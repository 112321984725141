import { Fragment } from 'react';
import styled from 'styled-components';
import { Dl, Dd, Dt } from 'components/atoms/Dl';
import Link from 'components/atoms/Link';

const Wrapper = styled.div``;

const Title = styled.h2``;

const getOptionValue = (connection) => {
  switch (connection.option.itemValueType) {
    case 'SPECIFIC':
      return connection.specificValue.value;
    case 'RANGE':
      return `от ${connection.rangeValue.fromValue} до ${connection.rangeValue.toValue}`;
    case 'SINGLE_SELECT':
      return connection.singleSelectValue.selectOptionValue.value;
    default:
      return 'не указано';
  }
};

const getOptionFilterType = (option) => {
  switch (option.filterType) {
    case 'MULTIPLE_SELECT':
      return 'multipleSelectValues';
    case 'SINGLE_SELECT':
      return 'value';
    default:
      return null;
  }
};

const getOptionValueLink = (categories, connection) => {
  const { option } = connection;
  const { categoriesConnections } = option;

  for (let i = 0; i < categoriesConnections.length; i += 1) {
    const categoryId = categoriesConnections[i].category.id;

    const category = categories.find((c) => c.id === categoryId);

    const filterType = getOptionFilterType(option);

    if (category) {
      if (filterType) {
        return `/catalog/${category.translit}?options[${option.id}][${filterType}]${
          filterType === 'multipleSelectValues' ? '[]' : ''
        }=${connection.singleSelectValue.selectOptionValue.id}`;
      }

      return null;
    }
  }

  return null;
};

const Options = ({ categories, connections }) => {
  if (connections.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Характеристики</Title>
      <Dl>
        {connections.map((connection) => {
          const link = getOptionValueLink(categories, connection);
          const optionValue = getOptionValue(connection);

          return (
            <Fragment key={connection.id}>
              <Dt>{connection.option.name}</Dt>
              <Dd>{link ? <Link to={link}>{optionValue}</Link> : optionValue}</Dd>
            </Fragment>
          );
        })}
      </Dl>
    </Wrapper>
  );
};

export default Options;
