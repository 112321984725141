import { Component } from 'react';
import styled, { css } from 'styled-components';
import Lightbox from 'react-images';
import constants from 'constants/constants';

const PUBLIC_BUCKET_URL = constants.PUBLIC_BUCKET_URL;

const Wrapper = styled.div`
  width: 100%;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 -0.5em;
    display: flex;
  }
`;

const ImageWrapper = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.neutral5};
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    margin: 0 0.5em;
    flex: 1 1 auto;
  }
`;

const Label = styled.div`
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  background-color: ${({ theme, color }) => theme.colors[color]};
  border-radius: 0.25em;
  padding: 0.5em 0.7em;
  font-size: 0.85em;
  font-weight: 600;
`;

const Previews = styled.div`
  width: 100%;
  display: flex;
  overflow-x: auto;
  height: 3.1em;
  margin-top: 0.5em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    display: block;
    margin: 0 0.5em;
    width: 3.1em;
    flex: none;
    height: auto;
  }
`;

const selectedPreviewCss = css`
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  opacity: 1;
`;

const Preview = styled.img`
  display: block;
  flex: none;
  opacity: 0.5;
  margin-right: 0.6em;
  height: 100%;

  ${({ isImageSelected }) => isImageSelected && selectedPreviewCss};

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    height: auto;
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.6em;
  }
`;

const Image = styled.img`
  display: block;
  width: 100%;
`;

const NoImageMessage = styled.div`
  margin: 2em 1em;
  text-align: center;
  font-weight: 600;
  font-size: 1.5em;
  color: ${({ theme }) => theme.colors.neutral4};
`;

export default class Images extends Component {
  state = {
    selectedImageIndex: 0,
    lightboxIsOpen: false,
  };

  selectImage = (selectedImageIndex) => {
    this.setState({
      selectedImageIndex,
    });
  };

  openLightbox = () => {
    this.setState({
      lightboxIsOpen: true,
    });
  };

  closeLightbox = () => {
    this.setState({
      lightboxIsOpen: false,
    });
  };

  gotoPrevLightboxImage = () => {
    this.setState((state) => {
      const prevIndex = state.selectedImageIndex - 1;

      return {
        selectedImageIndex: prevIndex < 0 ? this.props.item.images.length - 1 : prevIndex,
      };
    });
  };

  gotoNextLightboxImage = () => {
    this.setState((state) => {
      const nextIndex = state.selectedImageIndex + 1;

      return {
        selectedImageIndex: nextIndex > this.props.item.images.length - 1 ? 0 : nextIndex,
      };
    });
  };

  render() {
    const {
      item: { images, label, activeSaleItem },
    } = this.props;

    return (
      <>
        <Wrapper>
          <ImageWrapper onClick={images.length > 0 ? this.openLightbox : () => {}}>
            {images.length > 0 ? (
              <Image
                src={PUBLIC_BUCKET_URL + images[this.state.selectedImageIndex].value.thumb}
                alt="Изображение товара"
              />
            ) : (
              <NoImageMessage>Нет изображения</NoImageMessage>
            )}
            {activeSaleItem && <Label color="danger">Акция!</Label>}
            {!activeSaleItem && label && <Label color={label.color}>{label.text}</Label>}
          </ImageWrapper>
          {images.length > 1 && (
            <Previews>
              {images.map((image, i) => (
                <Preview
                  key={image.id}
                  src={PUBLIC_BUCKET_URL + image.value.thumb}
                  onClick={() => this.selectImage(i)}
                  isImageSelected={i === this.state.selectedImageIndex}
                  alt={`Изображение ${i + 1}`}
                />
              ))}
            </Previews>
          )}
        </Wrapper>
        <Lightbox
          images={images.map((image) => ({
            src: PUBLIC_BUCKET_URL + image.value.large,
            thumbnail: PUBLIC_BUCKET_URL + image.value.thumb,
          }))}
          currentImage={this.state.selectedImageIndex}
          isOpen={this.state.lightboxIsOpen}
          onClose={this.closeLightbox}
          imageCountSeparator=" из "
          onClickPrev={this.gotoPrevLightboxImage}
          onClickNext={this.gotoNextLightboxImage}
          onClickThumbnail={this.selectImage}
          showThumbnails={images.length > 1}
          backdropClosesModal
        />
      </>
    );
  }
}
