import { Component } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet-async';
import { Query } from 'react-apollo';
import { title, metaKeywords, metaDescription } from 'constants/meta';
import itemQuery from 'graphql/queries/item.graphql';
import itemLinks from 'graphql/queries/itemLinks.graphql';
import LoadingPage from 'components/pages/LoadingPage';
import ErrorLoadingPage from 'components/pages/ErrorLoadingPage';
import NotFoundPage from 'components/pages/NotFoundPage';
import MainTemplate from 'components/templates/MainTemplate';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import Redirect from 'components/atoms/Redirect';
import Images from './Images';
import Info from './Info';
import Options from './Options';
import LinkedItems from './LinkedItems';
import OtherItems from './OtherItems';

const LINKS_PER_PAGE = 4;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1em;
`;

const Title = styled.h1`
  font-size: 2em;
  font-weight: normal;
  margin: 0.3em 0 0.5em;
`;

const Article = styled.div`
  color: ${({ theme }) => theme.colors.neutral2};
`;

const ArticleText = styled.span`
  text-transform: uppercase;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  margin-top: -0.8em;
  margin-bottom: 2em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex-direction: row;
    align-items: flex-start;
    margin-left: -0.5em;
    margin-right: -0.5em;
  }
`;

const ImagesWrapper = styled.div`
  margin-top: 0.8em;
  margin-bottom: 0.8em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 40%;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`;

const StyledInfo = styled(Info)`
  margin-top: 0.8em;
  margin-bottom: 0.8em;

  @media (min-width: ${({ theme }) => theme.breakpoints[1]}em) {
    flex: 1 1 60%;
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
`;

const DescriptionWrapper = styled.div`
  margin-bottom: 3em;
`;

const Description = styled.div`
  color: ${({ theme }) => theme.colors.neutral2};
`;

class CatalogItemPage extends Component {
  state = {
    quantity: '1',
  };

  handleQuantityChange = (quantity) => {
    this.setState({
      quantity,
    });
  };

  afterAddToCard = () => {
    this.setState({
      quantity: '1',
    });
  };

  render() {
    let { match } = this.props;
    return (
      <Query
        query={itemQuery}
        variables={{ id: match.params.id, linksCount: LINKS_PER_PAGE }}
        notifyOnNetworkStatusChange
      >
        {({ loading, data, error, fetchMore }) => {
          if (loading && (!data || !data.item)) {
            return <LoadingPage />;
          }

          if (error) {
            return <ErrorLoadingPage />;
          }

          if (!data.item) {
            return <NotFoundPage />;
          }

          if (data.item.category.translit !== match.params.translit) {
            return (
              <Redirect
                status={301}
                to={`/catalog/${data.item.category.translit}/${data.item.id}`}
              />
            );
          }

          return (
            <MainTemplate>
              <Helmet>
                <title>{data.item.title || title.item(data.item)}</title>
                <meta
                  name="keywords"
                  content={data.item.metaKeywords || metaKeywords.item(data.item)}
                />
                <meta
                  name="description"
                  content={data.item.metaDescription || metaDescription.item(data.item)}
                />
              </Helmet>
              <Breadcrumbs
                links={[
                  {
                    link: '/catalog',
                    text: 'Каталог',
                  },
                  ...data.item.category.ancestors.map((ancestor) => ({
                    link: `/catalog/${ancestor.translit}`,
                    text: ancestor.name,
                  })),
                  {
                    link: `/catalog/${data.item.category.translit}`,
                    text: data.item.category.name,
                  },
                ]}
              />
              <TitleRow>
                <Title>{data.item.h1 || data.item.name}</Title>
                <Article>
                  Артикул: <ArticleText>{data.item.article}</ArticleText>
                </Article>
              </TitleRow>
              <Wrapper>
                <ImagesWrapper>
                  <Images item={data.item} />
                </ImagesWrapper>
                <StyledInfo
                  item={data.item}
                  quantity={this.state.quantity}
                  onQuantityChange={this.handleQuantityChange}
                  afterAddToCard={this.afterAddToCard}
                />
              </Wrapper>
              {data.item.description && (
                <DescriptionWrapper>
                  <h2>Описание</h2>
                  <Description dangerouslySetInnerHTML={{ __html: data.item.description }} />
                </DescriptionWrapper>
              )}
              <Options
                categories={[data.item.category, ...[...data.item.category.ancestors].reverse()]}
                connections={data.item.itemOptionsConnections}
              />
              <LinkedItems
                loading={loading}
                links={data.item.itemLinksToMe}
                showMoreButton={data.item.itemLinksToMe.length % LINKS_PER_PAGE === 0}
                onLoadMore={() => {
                  fetchMore({
                    query: itemLinks,
                    variables: {
                      whereInput: {
                        toItem: { id: { equals: data.item.id } },
                      },
                      perPage: LINKS_PER_PAGE,
                      offset: data.item.itemLinksToMe.length,
                    },
                    updateQuery: (prev, { fetchMoreResult }) => {
                      if (!fetchMoreResult) {
                        return prev;
                      }

                      return {
                        ...prev,
                        item: {
                          ...prev.item,
                          itemLinksToMe: [...prev.item.itemLinksToMe, ...fetchMoreResult.itemLinks],
                        },
                      };
                    },
                  });
                }}
              />
              <OtherItems
                categoryName={data.item.category.name}
                otherItemsAfterCurrent={data.item.category.otherItemsAfterCurrent}
                otherItemsFromStart={data.item.category.otherItemsFromStart}
              />
            </MainTemplate>
          );
        }}
      </Query>
    );
  }
}

export default CatalogItemPage;
