import styled from 'styled-components';
import ItemsList from 'components/molecules/ItemsList';
import Button from 'components/atoms/Button';

const Wrapper = styled.div`
  margin-top: 3em;
`;

const Title = styled.h2``;

const StyledItemsList = styled(ItemsList)`
  margin-bottom: 1em;
`;

const LinkedItems = ({ links, showMoreButton, onLoadMore, loading }) => {
  if (links.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>Сопутствующие товары</Title>
      <StyledItemsList
        hideDescription
        cols={[2, 3, 4]}
        items={links.map((link) => link.fromItem)}
      />
      {showMoreButton && (
        <Button neutral onClick={loading ? () => {} : onLoadMore}>
          {loading ? 'Загрузка...' : 'Показать ещё'}
        </Button>
      )}
    </Wrapper>
  );
};

export default LinkedItems;
