import styled from 'styled-components';
import AddToBookmarksButton from 'components/molecules/AddToBookmarksButton';
import AddToCartButton from 'components/molecules/AddToCartButton';
import QuantityInput from 'components/atoms/QuantityInput';
import AlternativePrices from './AlternativePrices';

const Wrapper = styled.div``;

const FirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const PriceWrapper = styled.div`
  margin: 0 -0.5rem 0.3rem;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
`;

const Price = styled.div`
  font-size: 1.85em;
  font-weight: 600;
  margin: 0 0.5rem 0.5rem;
`;

const PriceText = styled.span`
  font-size: 0.75em;
`;

const OldPrice = styled.div`
  font-size: 1.3em;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral3};
  text-decoration: line-through;
  margin: 0 0.5rem 0.5rem;
`;

const ResidueWrapper = styled.div`
  border-radius: 0.25em;
  border: 0.125em solid ${({ theme }) => theme.colors.neutral4};
  padding: 0.3em 0.4em;
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.colors.neutral2};
`;

const Residue = styled.div`
  display: inline-block;
  color: ${({ theme }) => theme.colors.text};
  background-color: ${({ theme }) => theme.colors.neutral4};
  border-radius: 0.3em;
  font-size: 0.9em;
  font-weight: 600;
  padding: 0.2em 0.4em;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -0.25em;
`;

const StyledAddToBookmarksButton = styled(AddToBookmarksButton)`
  margin-right: -0.25em;
  margin-left: -0.25em;
`;

const AddToCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StyledQuantityInput = styled(QuantityInput)`
  margin: 0.25em;
  flex: none;
`;

const StyledAddToCartButton = styled(AddToCartButton)`
  padding: 0.5em 2em;
  margin: 0.25em;
`;

const Remark = styled.p`
  font-size: 0.9em;
  color: ${({ theme }) => theme.colors.neutral3};
`;

const Info = ({ className, item, quantity, onQuantityChange, afterAddToCard }) => (
  <Wrapper className={className}>
    <FirstRow>
      <PriceWrapper>
        <Price>
          {item.price ? (
            <>
              <PriceText>Цена:</PriceText>
              {` ${item.price.toFixed(2)} ₽`}
            </>
          ) : (
            'Цена не указана'
          )}
        </Price>
        {item.activeSaleItem && item.activeSaleItem.oldPrice && (
          <OldPrice>{`${item.activeSaleItem.oldPrice.toFixed(2)} ₽`}</OldPrice>
        )}
        <OldPrice />
      </PriceWrapper>
      <ResidueWrapper>
        В наличии: <Residue>{item.residue}</Residue>
      </ResidueWrapper>
    </FirstRow>
    <AlternativePrices item={item} />
    <ActionsWrapper>
      <AddToCardWrapper>
        {item.residue > 0 && <StyledQuantityInput value={quantity} onChange={onQuantityChange} />}
        <StyledAddToCartButton quantity={quantity} item={item} afterSuccess={afterAddToCard} />
      </AddToCardWrapper>
      <StyledAddToBookmarksButton isBookmarked={item.isBookmarked} itemId={item.id} />
    </ActionsWrapper>
    {item.residue > 0 ? (
      <Remark>
        Цена действительна только для интернет-магазина и может отличаться от цен в розничных
        магазинах
      </Remark>
    ) : (
      <Remark>Внимание! Цена указана на момент последнего наличия товара!</Remark>
    )}
  </Wrapper>
);

export default Info;
