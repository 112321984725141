import styled from 'styled-components';
import ItemsList from 'components/molecules/ItemsList';

const Wrapper = styled.div`
  margin-top: 3em;
`;

const Title = styled.h2``;

const StyledItemsList = styled(ItemsList)`
  margin-bottom: 1em;
`;

const onlyUnique = (currentItem, currentIndex, self) => {
  const foundIndex = self.findIndex((item) => item.id === currentItem.id);

  return currentIndex === foundIndex;
};

const OtherItems = ({ otherItemsAfterCurrent, otherItemsFromStart, categoryName }) => {
  const stack = [...otherItemsAfterCurrent, ...otherItemsFromStart].filter(onlyUnique);

  if (stack.length === 0) {
    return null;
  }

  return (
    <Wrapper>
      <Title>{`Другие товары в категории "${categoryName}"`}</Title>
      <StyledItemsList hideDescription cols={[2, 3, 4]} items={stack.slice(0, 4)} />
    </Wrapper>
  );
};

export default OtherItems;
