import { Fragment } from 'react';
import styled from 'styled-components';
import { Dd, Dl, Dt } from 'components/atoms/Dl';

const StyledDl = styled(Dl)`
  margin-bottom: 2em;
`;

const Price = ({ kind, value, price }) => {
  switch (kind) {
    case 'NEW_PRICE':
      return `${value.toFixed(2)} ₽`;
    case 'DISCOUNT_PERCENTAGE': {
      if (price) {
        return `${price} ₽ (скидка ${value}%)`;
      }
      return `скидка ${value}%`;
    }
    case 'DEDUCTION': {
      if (price) {
        return `${price} ₽ (скидка ${value.toFixed(2)} ₽)`;
      }
      return `скидка ${value.toFixed(2)} ₽`;
    }
    default:
      return null;
  }
};

const AlternativePrices = ({ item }) => (
  <StyledDl>
    {item.allAlternativePrices.map((price) => (
      <Fragment key={price.type.id}>
        <Dt>{`От ${price.threshold}`}</Dt>
        <Dd>
          <Price {...price} />
        </Dd>
      </Fragment>
    ))}
  </StyledDl>
);

export default AlternativePrices;
